<template>
  <b-modal
    @shown="updateItem"
    title="編輯在地養成公費生歷程"
    id="editProgressRecord"
    size="xl"
    @hide="doSometing"
  >
    <b-container fluid>
      <div class="grid-head">
        <b-input-group prepend="姓名">
          <b-input
            v-model.trim="item.name"
            placeholder="姓名"
            readonly
          ></b-input>
        </b-input-group>
        <b-input-group prepend="身分證字號">
          <b-input
            v-model.trim="item.id"
            placeholder="身分證字號"
            readonly
          ></b-input>
        </b-input-group>
        <b-input-group prepend="性別">
          <b-input
            v-model.trim="item.gender"
            placeholder="性別"
            readonly
          ></b-input>
        </b-input-group>
        <b-input-group prepend="培育學校">
          <b-input
            v-model.trim="item.school"
            placeholder="培育學校"
            readonly
          ></b-input>
        </b-input-group>
        <b-input-group prepend="科別">
          <b-input
            v-model.trim="item.cat"
            placeholder="科別"
            readonly
          ></b-input>
        </b-input-group>
        <b-input-group prepend="備註" style="grid-column: 1/-1;">
          <b-form-textarea
            id="textarea"
            v-model="item.originRemark"
            placeholder="請輸入備註..."
            rows="4"
            max-rows="6"
            readonly
          ></b-form-textarea>
        </b-input-group>
      </div>
      <div class="grid-content">
        <div
          v-show="Boolean(item.cat == '醫師') || Boolean(item.cat == '牙醫')"
        >
          <b-button variant="success" @click="tab = 1">PGY培訓</b-button>
        </div>
        <div
          v-show="Boolean(item.cat == '醫師') || Boolean(item.cat == '牙醫')"
        >
          <b-button
            variant="success"
            @click="clickTabTwo"
            :disabled="!Boolean(allPGYitem.length)"
            >專科培訓</b-button
          >
        </div>
        <div
          v-show="!(Boolean(item.cat == '醫師') || Boolean(item.cat == '牙醫'))"
        >
          <b-button variant="success" @click="tab = 3">其他醫事訓練</b-button>
        </div>
        <div>
          <b-button
            variant="success"
            @click="clickTabFour"
            :disabled="
              (!Boolean(trainingItems.length) && item.cat == '醫師') ||
                (!Boolean(trainingItems.length) && item.cat == '牙醫') ||
                (!Boolean(allOtherPracticeitem.length) &&
                  item.cat != '醫師' &&
                  item.cat != '牙醫')
            "
            >服務期間</b-button
          >
        </div>
      </div>
      <div class="main-conmtent" v-if="tab == 1">
        <h5>PGY培訓</h5>
        <b-input-group prepend="公文號">
          <b-input v-model.trim="pgyDocNum" placeholder="公文號"></b-input>
        </b-input-group>
        <b-input-group prepend="培訓期間">
          <date-picker-tw-year
            :key="`pgyStartTime${compontKey}`"
            :time="pgyStartTime"
            @update="
              (t) => {
                pgyStartTime = t;
              }
            "
          ></date-picker-tw-year>
          <span>~</span>
          <date-picker-tw-year
            :key="`pgyEndTime${compontKey}`"
            :time="pgyEndTime"
            @update="
              (t) => {
                pgyEndTime = t;
              }
            "
          ></date-picker-tw-year>
        </b-input-group>
        <b-input-group prepend="備註">
          <b-input
            v-model.trim="pgyComment"
            placeholder="輸入字數為30字以內"
          ></b-input>
        </b-input-group>

        <b-button
          variant="primary"
          class="float-right ml-3 my-2"
          @click="$bvModal.hide('editProgressRecord')"
          >關閉</b-button
        >
        <b-button
          variant="danger"
          class="float-right ml-3 my-2"
          @click="savePGYData"
          >儲存</b-button
        >
        <b-button
          variant="warning"
          class="float-right ml-3 my-2"
          @click="clearPGYData"
          >清除</b-button
        >
      </div>
      <div class="main-conmtent main-grid-2" v-if="tab == 2">
        <h5 style="grid-column: 1/-1;">專科培訓</h5>
        <b-input-group prepend="公文號">
          <b-input v-model.trim="trainingDocNum" placeholder="公文號"></b-input>
        </b-input-group>
        <b-input-group prepend="原因說明">
          <b-select
            :options="
              trainingItems.length <= 0
                ? ['申請受訓']
                : ['延展受訓', '延展服務', '轉換機構']
            "
            v-model="trainingReason"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="培訓專科">
          <b-select
            :options="divisionOptions"
            v-model="trainingSpec"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="培訓年限">
          <b-input
            type="number"
            v-model.trim="trainingYear"
            placeholder="年"
            min="0"
            max="9"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="培訓期間" style="grid-column: 2/-1;">
          <date-picker-tw-year
            :key="`trainingStartTime${compontKey}`"
            :time="trainingStartTime"
            @update="
              (t) => {
                trainingStartTime = t;
              }
            "
          ></date-picker-tw-year>
          <span>~</span>
          <date-picker-tw-year
            :key="`trainingEndTime${compontKey}`"
            :time="trainingEndTime"
            @update="
              (t) => {
                trainingEndTime = t;
              }
            "
          ></date-picker-tw-year>
        </b-input-group>
        <b-input-group prepend="備註" style="grid-column: 1/-1;">
          <b-input
            v-model.trim="trainingComment"
            placeholder="輸入字數為30字以內"
          ></b-input>
        </b-input-group>
      </div>
      <div class="main-conmtent main-conmtent-three" v-if="tab == 3">
        <h5 style="grid-column: 1/-1;">其他醫事訓練</h5>
        <b-input-group prepend="公文號">
          <b-input
            v-model.trim="otherPracticeDocNum"
            placeholder="公文號"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="培訓年限">
          <b-input
            type="number"
            v-model.trim="otherPracticeYear"
            placeholder="年"
            min="0"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="培訓期間" style="grid-column: 1/-1;">
          <date-picker-tw-year
            :key="`otherPracticeStartTime${compontKey}`"
            :time="otherPracticeStartTime"
            @update="
              (t) => {
                otherPracticeStartTime = t;
              }
            "
          ></date-picker-tw-year>
          <span>~</span>
          <date-picker-tw-year
            :key="`otherPracticeEndTime${compontKey}`"
            :time="otherPracticeEndTime"
            @update="
              (t) => {
                otherPracticeEndTime = t;
              }
            "
          ></date-picker-tw-year>
        </b-input-group>
        <b-input-group prepend="備註" style="grid-column: 1/-1;">
          <b-input
            v-model.trim="otherPracticeComment"
            placeholder="輸入字數為30字以內"
          ></b-input>
        </b-input-group>
      </div>
      <b-button
        variant="primary"
        class="float-right ml-3 my-2"
        @click="$bvModal.hide('editProgressRecord')"
        v-if="tab == 3"
        >關閉</b-button
      >
      <b-button
        variant="danger"
        class="float-right ml-3 my-2"
        @click="saveOtherPracticeData"
        v-if="tab == 3"
        >儲存</b-button
      >
      <b-button
        variant="warning"
        class="float-right ml-3 my-2"
        @click="clearOtherPracticeData"
        v-if="tab == 3"
        >清除</b-button
      >
      <div class="main-conmtent main-conmtent-three" v-if="tab == 4">
        <h5 style="grid-column: 1/-1;">
          服務總期間<b-button
            variant="outline-info"
            class="ml-3"
            @click="addServiceDetail"
            >新增服務明細</b-button
          >
        </h5>

        <b-input-group prepend="公文號">
          <b-input v-model.trim="serviceDocNum" placeholder="公文號"></b-input>
        </b-input-group>
        <b-input-group prepend="服務年限">
          <b-input
            type="number"
            v-model.trim="serviceYear"
            placeholder="年"
            min="0"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="服務期間" style="grid-column: 1/-1;">
          <date-picker-tw-year
            :key="`serviceStartTime${compontKey}`"
            :time="serviceStartTime"
            @update="
              (t) => {
                serviceStartTime = t;
              }
            "
          ></date-picker-tw-year>
          <span>~</span>
          <date-picker-tw-year
            :key="`serviceEndTime${compontKey}`"
            :time="serviceEndTime"
            @update="
              (t) => {
                serviceEndTime = t;
              }
            "
          ></date-picker-tw-year>
        </b-input-group>
        <b-input-group prepend="備註" style="grid-column: 1/-1;">
          <b-input
            v-model.trim="serviceComment"
            placeholder="輸入字數為30字以內"
          ></b-input>
        </b-input-group>
      </div>
      <b-button
        variant="primary"
        class="mr-3"
        v-if="tab == 4"
        @click="changeServicePlace"
        style="display:none"
        >變更服務地點</b-button
      >
      <div
        v-show="tab == 4"
        v-for="(item, i) in serviceItems"
        :key="`serviceItems${i}`"
        class="service-place mt-2"
      >
        <b-input-group prepend="核定文號">
          <b-input
            v-model.trim="item.docNum"
            placeholder="輸入核定文號"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="生效日期">
          <b-input v-model.trim="item.startDate" type="date"></b-input>
        </b-input-group>
        <b-input-group prepend="服務機構">
          <b-input
            v-model.trim="item.servicePlace"
            placeholder="輸入服務機構"
          ></b-input>
        </b-input-group>
        <b-button
          variant="danger"
          class="float-right ml-2 service-delete"
          size="sm"
          @click="deleteServiceItem"
          >清除</b-button
        >
      </div>
      <div v-show="tab == 2">
        <header class="dtc-grid-header my-dark">
          <div
            v-for="(item, i) in trainingHeaders"
            :key="`headers${i}`"
            :title="item"
            style="background:#424242;color:#fff"
          >
            {{ item }}
          </div>
        </header>
        <main
          class="dtc-grid-header2"
          v-for="(item, i) in trainingItems"
          :key="i"
          style="color:#39312E;"
          :style="
            i % 2 == 0
              ? 'background-color: #EDEDED;'
              : 'background-color: #DDDDDD;'
          "
        >
          <div>
            <b-button variant="danger" size="sm" @click="deleteTrainItem(item)"
              >刪除</b-button
            >
          </div>
          <div :title="item.Doc_No">{{ item.Doc_No || "暫無資料" }}</div>
          <div :title="item.T_Kind">{{ item.T_Kind || "暫無資料" }}</div>
          <div :title="item.division">{{ item.division || "暫無資料" }}</div>
          <div :title="item.T_Year">{{ item.T_Year || "暫無資料" }}</div>
          <div :title="item.StartDate">
            {{ item.StartDate ? $twDate2(item.StartDate) : "暫無資料" }}
          </div>
          <div :title="item.EndDate">
            {{ item.EndDate ? $twDate2(item.EndDate) : "暫無資料" }}
          </div>
          <div :title="item.Comment">
            {{ item.Comment || "暫無資料" }}
          </div>
        </main>
      </div>
      <div v-show="tab == 4">
        <header class="dtc-grid-header3 my-dark">
          <div
            v-for="(item, i) in serviceHeaders"
            :key="`headers${i}`"
            :title="item"
            style="background:#424242;color:#fff"
          >
            {{ item }}
          </div>
        </header>
        <main
          class="dtc-grid-header4"
          v-for="(item, i) in serviceItemsData"
          :key="i"
          style="color:#39312E;"
          :style="
            i % 2 == 0
              ? 'background-color: #EDEDED;'
              : 'background-color: #DDDDDD;'
          "
        >
          <div>
            <b-button variant="danger" size="sm" @click="deleteTrainItem(item)"
              >刪除</b-button
            >
          </div>
          <div :title="item.Doc_No">{{ item.Doc_No || "暫無資料" }}</div>
          <div :title="item.T_Kind">{{ item.T_Kind || "暫無資料" }}</div>
          <div :title="item.StartDate">
            {{ item.StartDate ? $twDate2(item.StartDate) : "暫無資料" }}
          </div>
          <div :title="item.EndDate">
            {{ item.EndDate ? $twDate2(item.EndDate) : "暫無資料" }}
          </div>
          <div :title="item.Comment">
            {{ item.Comment || "暫無資料" }}
          </div>
        </main>
      </div>
    </b-container>
    <b-button
      v-show="tab == 2 || tab == 4"
      variant="primary"
      class="float-right ml-3 my-2 mt-4 mr-3"
      @click="$bvModal.hide('editProgressRecord')"
      >關閉</b-button
    >
    <b-button
      v-show="tab == 2"
      variant="danger"
      class="float-right ml-3 my-2 mt-4"
      @click="addTrainingData"
      >新增</b-button
    >
    <b-button
      v-show="tab == 2"
      variant="warning"
      class="float-right ml-3 my-2 mt-4"
      @click="clearTrainingData"
      >清除</b-button
    >
    <b-button
      v-show="tab == 4"
      variant="danger"
      class="float-right ml-3 my-2 mt-4"
      @click="saveServiceData"
      >儲存</b-button
    >
    <b-button
      v-show="tab == 4"
      variant="warning"
      class="float-right ml-3 my-2 mt-4"
      @click="clearServiceData"
      >清除</b-button
    >
    <b-modal id="modalAddServiceDetail" title="新增服務期間明細">
      <main class="dtc-service-grid">
        <b-input-group prepend="公文號">
          <b-input
            v-model.trim="modalService.Doc_No"
            placeholder="公文號"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="開始時間" style="grid-column: 1/-1;">
          <!-- <b-input
            type="date"
            v-model.trim="modalService.StartDate"
            placeholder="開始時間"
          ></b-input> -->
          <date-picker-tw-year
            :key="`modalServiceStartDate${compontKey}`"
            :time="modalService.StartDate"
            @update="
              (t) => {
                modalService.StartDate = t;
              }
            "
          ></date-picker-tw-year>
        </b-input-group>
        <b-input-group prepend="結束時間" style="grid-column: 1/-1;">
          <!-- <b-input
            type="date"
            v-model.trim="modalService.EndDate"
            placeholder="結束時間"
          ></b-input> -->
          <date-picker-tw-year
            :key="`modalServiceEndDate${compontKey}`"
            :time="modalService.EndDate"
            @update="
              (t) => {
                modalService.EndDate = t;
              }
            "
          ></date-picker-tw-year>
        </b-input-group>
        <b-input-group prepend="原因" style="grid-column: 1/-1;">
          <b-input
            v-model.trim="modalService.T_Kind"
            placeholder="輸入字數為30字以內"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="備註" style="grid-column: 1/-1;">
          <b-input
            v-model.trim="modalService.Comment"
            placeholder="輸入字數為30字以內"
          ></b-input>
        </b-input-group>
      </main>
      <template v-slot:modal-footer>
        <div class="w-100 text-right">
          <b-button
            variant="primary"
            class="ml-3 mr-1"
            @click="addServiceDetailData"
            >確認
          </b-button>

          <b-button
            variant="info"
            class="ml-2"
            @click="$bvModal.hide('modalAddServiceDetail')"
            >關閉</b-button
          >
        </div>
      </template>
    </b-modal>

    <!-- <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="success"
          class="float-right ml-3"
          @click="$bvModal.hide('editProgressRecord')"
          style="margin-right:19px"
          >返回</b-button
        >

        <b-button
          variant="primary"
          class="float-right ml-3"
          @click="addTrainingData"
          v-if="tab == 2"
          >新增</b-button
        >
      </div>
    </template> -->
  </b-modal>
</template>

<script>
import { store } from "@/store/global.js";
import moment from "moment";
const trainingHeaders = [
  "操作",
  "公文號",
  "原因說明",
  "培訓專科",
  "培訓年限",
  "培訓開始時間",
  "培訓結束時間",
  "備註",
];
const serviceHeaders = [
  "操作",
  "公文號",
  "原因",
  "服務開始時間",
  "服務結束時間",
  "備註",
];
export default {
  name: "editProgressRecord",
  props: ["divisionOptions"],
  data() {
    return {
      compontKey: 0,

      item: {},
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      items: [],
      tab: 0,

      //for check auto add year
      isNewPGYEndTime: false,
      isNewOtherPracticeEndTime: false,
      isNewServiceDataEndTime: false,

      //for check purpose
      allPGYitem: [],

      pgyDocNum: "",
      pgyStartTime: null,
      pgyEndTime: null,
      pgyComment: "",
      trainingDocNum: "",
      trainingReason: "",
      trainingSpec: "",
      trainingYear: "",
      trainingStartTime: null,
      trainingEndTime: null,
      trainingComment: "",

      //for check purpose
      allOtherPracticeitem: [],

      otherPracticeDocNum: "",
      otherPracticeYear: "",
      otherPracticeStartTime: null,
      otherPracticeEndTime: null,
      otherPracticeComment: "",

      serviceDocNum: "",
      serviceYear: "",
      serviceStartTime: null,
      serviceEndTime: null,
      serviceComment: "",

      serviceItems: [],
      serviceItemsData: [],

      trainingHeaders,
      serviceHeaders,
      isExistPGYData: false,
      pgySeqNumber: "",
      trainingItems: [],

      isExistOtherPracticeData: false,
      otherPracticeSeqNumber: "",

      isExistServiceData: false,
      serviceSeqNumber: "",

      modalService: {
        Doc_No: "",
        StartDate: null,
        EndDate: null,
        T_Kind: "",
        Comment: "",
      },
    };
  },
  computed: {},
  methods: {
    countOccurences(string, word) {
      if (!Boolean(string)) {
        return 0;
      } else {
        return string.split(word).length - 1;
      }
    },
    addServiceDetail() {
      this.modalService = {
        Doc_No: "",
        StartDate: null,
        EndDate: null,
        T_Kind: "",
        Comment: "",
        Category: "T",
        Identifier: this.item.id,
      };
      this.$bvModal.show("modalAddServiceDetail");
    },
    async addServiceDetailData() {
      const obj = { ...this.modalService };
      obj.StartDate = this.$usDate(this.modalService.StartDate);
      obj.EndDate = this.$usDate(this.modalService.EndDate);

      if (!Boolean(obj.StartDate)) {
        this.$bvToast.toast("需要完成服務期間資料填寫才可新增服務明細資料", {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }

      if (this.item.cat == "醫師" || this.item.cat == "牙醫") {
        if (!Boolean(this.trainingItems[0].EndDateNumber)) {
          this.$bvToast.toast(
            "需要完成專科培訓期間資料填寫才可新增服務明細資料",
            {
              title: "系統資訊",
              autoHideDelay: 5000,
              variant: "danger",
            }
          );
          return;
        }

        if (this.trainingItems[0].EndDateNumber > Date.parse(obj.StartDate)) {
          this.$bvToast.toast(
            "服務開始時間請勿小於專科培訓結束時間，請進行調整才可新增服務明細資料",
            {
              title: "系統資訊",
              autoHideDelay: 5000,
              variant: "danger",
            }
          );
          return;
        }
      } else {
        if (!Boolean(this.otherPracticeEndTime)) {
          this.$bvToast.toast(
            "需要完成其他醫事訓練期間資料填寫才可新增服務明細資料",
            {
              title: "系統資訊",
              autoHideDelay: 5000,
              variant: "danger",
            }
          );
          return;
        }

        if (
          Date.parse(this.$usDate(this.otherPracticeEndTime)) >
          Date.parse(obj.StartDate)
        ) {
          this.$bvToast.toast(
            "服務開始時間請勿小於其他醫事訓練培訓結束時間，請進行調整新增服務明細資料",
            {
              title: "系統資訊",
              autoHideDelay: 5000,
              variant: "danger",
            }
          );
          return;
        }
      }

      const postUrl = "StudentTraining/Post";
      try {
        await window.axios.post(postUrl, obj);
        this.modalService.StartDate = null;
        this.modalService.EndDate = null;
        this.$bvModal.hide("modalAddServiceDetail");
        this.getServiceDetailData();
        this.$bvToast.toast("新增成功", {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        this.$bvToast.toast(`新增失敗` + e, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getServiceDetailData() {
      const id = this.item.id;
      const url = `StudentTraining/Get?identifier=${id}&category=T`;
      const res = await axios.get(url);
      this.serviceItemsData = res.map((s) => {
        s.StartDate = Boolean(s.StartDate) ? s.StartDate.split("T")[0] : null;
        s.EndDate = Boolean(s.EndDate) ? s.EndDate.split("T")[0] : null;
        s.EndDateNumber = Date.parse(s.EndDate);
        return s;
      });
      this.serviceItemsData.sort(function(a, b) {
        return b.EndDateNumber - a.EndDateNumber;
      });
    },
    doSometing() {
      this.$emit("updateLocalProgress");
    },
    async updateItem() {
      this.item = { ...store.editItem };
      this.allPGYitem = [];
      this.pgyDocNum = "";
      this.pgyStartTime = null;
      this.pgyEndTime = null;
      this.pgyComment = "";

      this.trainingDocNum = "";
      this.trainingReason = "";
      this.trainingSpec = "";
      this.trainingYear = "";
      this.trainingStartTime = null;
      this.trainingEndTime = null;
      this.trainingComment = "";

      this.allOtherPracticeitem = [];
      this.otherPracticeDocNum = "";
      this.otherPracticeYear = "";
      this.otherPracticeStartTime = null;
      this.otherPracticeEndTime = null;
      this.otherPracticeComment = "";

      this.serviceDocNum = "";
      this.serviceYear = "";
      this.serviceStartTime = null;
      this.serviceEndTime = null;
      this.serviceComment = "";

      this.serviceItems = [];
      this.serviceItemsData = [];
      this.compontKey = 0;

      if (
        Boolean(this.item.cat == "醫師") ||
        Boolean(this.item.cat == "牙醫")
      ) {
        this.tab = 1;
        await this.getPGYData();
        await this.getTrainingData();
      } else {
        this.tab = 3;
        await this.getOtherPracticeData();
      }
      await this.getServiceData();
      await this.getServiceDetailData();
    },
    async getPGYData() {
      const id = this.item.id;
      const url = `StudentTraining/Get?identifier=${id}&category=P`;
      const res = await axios.get(url);
      this.allPGYitem = JSON.parse(JSON.stringify(res));
      this.pgyDocNum = res.length ? res[0].Doc_No : "";
      this.pgyStartTime = res.length
        ? this.$twDate2(moment(res[0].StartDate).format("YYYY-MM-DD"))
        : null;
      this.pgySeqNumber = res.length ? res[0].SeqNo : "";
      this.pgyEndTime = res.length
        ? this.$twDate2(moment(res[0].EndDate).format("YYYY-MM-DD"))
        : null;
      this.isNewPGYEndTime = res.length ? !Boolean(res[0].EndDate) : true;
      this.isExistPGYData = res.length ? true : false;
      this.pgyComment = res.length ? res[0].Comment : "";
      this.compontKey += 1;
    },
    async savePGYData() {
      const objPut = {
        SeqNo: this.pgySeqNumber,
        Category: "P",
        Identifier: this.item.id,
        Doc_No: this.pgyDocNum ? this.pgyDocNum : null,
        StartDate: this.pgyStartTime ? this.$usDate(this.pgyStartTime) : null,
        EndDate: this.pgyEndTime ? this.$usDate(this.pgyEndTime) : null,
        Comment: this.pgyComment ? this.pgyComment : null,
        T_Year: null,
        T_Division: null,
        T_Kind: null,
      };
      const objPost = {
        Category: "P",
        Identifier: this.item.id,
        Doc_No: this.pgyDocNum ? this.pgyDocNum : null,
        StartDate: this.pgyStartTime ? this.$usDate(this.pgyStartTime) : null,
        EndDate: this.pgyEndTime ? this.$usDate(this.pgyEndTime) : null,
        Comment: this.pgyComment ? this.pgyComment : null,
        T_Year: null,
        T_Division: null,
        T_Kind: null,
      };

      const putUrl = `StudentTraining/Put`;
      const postUrl = `StudentTraining/Post`;

      try {
        if (this.isExistPGYData) {
          await window.axios.put(putUrl, objPut);
        } else {
          await window.axios.post(postUrl, objPost);
          this.getPGYData();
        }
        this.$bvToast.toast(`儲存成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        this.$bvToast.toast(`儲存失敗` + e, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    clearPGYData() {
      this.pgyDocNum = "";
      // this.pgyStartTime = null;
      // this.pgyEndTime = null;
      this.pgyComment = "";
    },
    clickTabTwo() {
      this.tab = 2;
      // this.getTrainingData();
    },
    async getTrainingData() {
      const id = this.item.id;
      const url = `StudentTraining/Get?identifier=${id}&category=E`;
      let res = await axios.get(url);

      this.trainingItems = res.map((s) => {
        s.StartDate = Boolean(s.StartDate) ? s.StartDate.split("T")[0] : null;
        s.EndDate = Boolean(s.EndDate) ? s.EndDate.split("T")[0] : null;
        s.division = Boolean(s.T_Division)
          ? this.divisionOptions.find((k) => k.value == s.T_Division).text
          : null;
        s.EndDateNumber = Date.parse(s.EndDate);
        return s;
      });
      this.trainingItems.sort(function(a, b) {
        return b.EndDateNumber - a.EndDateNumber;
      });
    },
    async addTrainingData() {
      const objPost = {
        Category: "E",
        Identifier: this.item.id,
        Doc_No: this.trainingDocNum ? this.trainingDocNum : null,
        StartDate: this.trainingStartTime
          ? this.$usDate(this.trainingStartTime)
          : null,
        EndDate: this.trainingEndTime
          ? this.$usDate(this.trainingEndTime)
          : null,
        Comment: this.trainingComment ? this.trainingComment : null,
        T_Year: this.trainingYear ? this.trainingYear : null,
        T_Division: this.trainingSpec ? this.trainingSpec : null,
        T_Kind: this.trainingReason ? this.trainingReason : null,
      };
      if (!Boolean(this.pgyEndTime)) {
        this.$bvToast.toast("需要完成PGY期間填寫並儲存才可新增專科培訓資料", {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }
      if (!Boolean(this.trainingStartTime)) {
        this.$bvToast.toast("需要完成培訓期間資料填寫才可新增專科培訓資料", {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }
      if (
        Date.parse(this.$usDate(this.pgyEndTime)) >
        Date.parse(this.$usDate(this.trainingStartTime))
      ) {
        this.$bvToast.toast(
          "專科培訓開始時間請勿小於PGY培訓結束時間，請進行調整才可新增專科培訓資料",
          {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "danger",
          }
        );
        return;
      }

      const postUrl = `StudentTraining/Post`;

      try {
        await window.axios.post(postUrl, objPost);
        this.getTrainingData();
        this.$bvToast.toast(`新增成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.trainingDocNum = null;
        this.trainingStartTime = null;
        this.trainingEndTime = null;
        this.trainingComment = null;
        this.trainingYear = null;
        this.trainingSpec = null;
        this.trainingReason = null;
      } catch (e) {
        this.$bvToast.toast(`新增失敗` + e, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    clearTrainingData() {
      this.trainingDocNum = "";
      this.trainingReason = "";
      this.trainingSpec = "";
      this.trainingYear = "";
      // this.trainingStartTime = null;
      // this.trainingEndTime = null;
      this.trainingComment = "";
    },
    async deleteTrainItem(item) {
      const url = `StudentTraining/Delete?SeqNo=${item.SeqNo}`;
      try {
        await window.axios.delete(url);
        if (this.tab == 2) {
          this.getTrainingData();
        } else {
          this.getServiceDetailData();
        }

        this.$bvToast.toast(`刪除成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        this.$bvToast.toast(`刪除失敗` + e, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getOtherPracticeData() {
      const id = this.item.id;
      const url = `StudentTraining/Get?identifier=${id}&category=E`;
      const res = await axios.get(url);

      this.allOtherPracticeitem = JSON.parse(JSON.stringify(res));

      this.otherPracticeDocNum = res.length ? res[0].Doc_No : "";
      this.otherPracticeStartTime = res.length
        ? this.$twDate2(moment(res[0].StartDate).format("YYYY-MM-DD"))
        : null;
      this.otherPracticeSeqNumber = res.length ? res[0].SeqNo : "";
      this.otherPracticeEndTime = res.length
        ? this.$twDate2(moment(res[0].EndDate).format("YYYY-MM-DD"))
        : null;
      this.isNewOtherPracticeEndTime = res.length
        ? !Boolean(res[0].EndDate)
        : true;

      this.isExistOtherPracticeData = res.length ? true : false;
      this.otherPracticeComment = res.length ? res[0].Comment : "";
      this.otherPracticeYear = res.length ? res[0].T_Year : "";
      this.compontKey += 1;
    },
    async saveOtherPracticeData() {
      const objPut = {
        SeqNo: this.otherPracticeSeqNumber,
        Category: "E",
        Identifier: this.item.id,
        Doc_No: this.otherPracticeDocNum ? this.otherPracticeDocNum : null,
        StartDate: this.otherPracticeStartTime
          ? this.$usDate(this.otherPracticeStartTime)
          : null,
        EndDate: this.otherPracticeEndTime
          ? this.$usDate(this.otherPracticeEndTime)
          : null,
        Comment: this.otherPracticeComment ? this.otherPracticeComment : null,
        T_Year: this.otherPracticeYear ? this.otherPracticeYear : null,
        T_Division: null,
        T_Kind: null,
      };
      const objPost = {
        Category: "E",
        Identifier: this.item.id,
        Doc_No: this.otherPracticeDocNum ? this.otherPracticeDocNum : null,
        StartDate: this.otherPracticeStartTime
          ? this.$usDate(this.otherPracticeStartTime)
          : null,
        EndDate: this.otherPracticeEndTime
          ? this.$usDate(this.otherPracticeEndTime)
          : null,
        Comment: this.otherPracticeComment ? this.otherPracticeComment : null,
        T_Year: this.otherPracticeYear ? this.otherPracticeYear : null,
        T_Division: null,
        T_Kind: null,
      };

      const putUrl = `StudentTraining/Put`;
      const postUrl = `StudentTraining/Post`;

      try {
        if (this.isExistOtherPracticeData) {
          await window.axios.put(putUrl, objPut);
        } else {
          await window.axios.post(postUrl, objPost);
          this.getOtherPracticeData();
        }
        this.$bvToast.toast(`儲存成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        this.$bvToast.toast(`儲存失敗` + e, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    clearOtherPracticeData() {
      this.otherPracticeDocNum = "";
      this.otherPracticeYear = "";
      // this.otherPracticeStartTime = null;
      // this.otherPracticeEndTime = null;
      this.otherPracticeComment = "";
    },

    async getServiceData() {
      const id = this.item.id;
      const url = `StudentTraining/Get?identifier=${id}&category=S`;
      const res = await axios.get(url);
      this.serviceDocNum = res.length ? res[0].Doc_No : "";
      this.serviceStartTime = res.length
        ? this.$twDate2(moment(res[0].StartDate).format("YYYY-MM-DD"))
        : null;
      this.serviceSeqNumber = res.length ? res[0].SeqNo : "";
      this.serviceEndTime = res.length
        ? this.$twDate2(moment(res[0].EndDate).format("YYYY-MM-DD"))
        : null;
      this.isExistServiceData = res.length ? true : false;
      this.serviceComment = res.length ? res[0].Comment : "";
      this.serviceYear = res.length ? res[0].T_Year : "";

      this.isNewServiceDataEndTime = res.length
        ? !Boolean(res[0].EndDate)
        : true;
      this.compontKey += 1;
    },
    async saveServiceData() {
      // this.serviceStartTime

      if (!Boolean(this.serviceStartTime)) {
        this.$bvToast.toast("需要完成服務期間資料填寫才可儲存服務總期間資料", {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }

      if (this.item.cat == "醫師" || this.item.cat == "牙醫") {
        if (!Boolean(this.trainingItems[0].EndDateNumber)) {
          this.$bvToast.toast(
            "需要完成專科培訓期間資料填寫才可儲存服務總期間資料",
            {
              title: "系統資訊",
              autoHideDelay: 5000,
              variant: "danger",
            }
          );
          return;
        }

        if (
          this.trainingItems[0].EndDateNumber >
          Date.parse(this.$usDate(this.serviceStartTime))
        ) {
          this.$bvToast.toast(
            "服務開始時間請勿小於專科培訓結束時間，請進行調整才可儲存服務總期間資料",
            {
              title: "系統資訊",
              autoHideDelay: 5000,
              variant: "danger",
            }
          );
          return;
        }
      } else {
        if (!Boolean(this.otherPracticeEndTime)) {
          this.$bvToast.toast(
            "需要完成其他醫事訓練期間資料填寫才可儲存服務總期間資料",
            {
              title: "系統資訊",
              autoHideDelay: 5000,
              variant: "danger",
            }
          );
          return;
        }

        if (
          Date.parse(this.$usDate(this.otherPracticeEndTime)) >
          Date.parse(this.$usDate(this.serviceStartTime))
        ) {
          this.$bvToast.toast(
            "服務開始時間請勿小於其他醫事訓練培訓結束時間，請進行調整才可儲存服務總期間資料",
            {
              title: "系統資訊",
              autoHideDelay: 5000,
              variant: "danger",
            }
          );
          return;
        }
      }

      const objPut = {
        SeqNo: this.serviceSeqNumber,
        Category: "S",
        Identifier: this.item.id,
        Doc_No: this.serviceDocNum ? this.serviceDocNum : null,
        StartDate: this.serviceStartTime
          ? this.$usDate(this.serviceStartTime)
          : null,
        EndDate: this.serviceEndTime ? this.$usDate(this.serviceEndTime) : null,
        Comment: this.serviceComment ? this.serviceComment : null,
        T_Year: this.serviceYear ? this.serviceYear : null,
        T_Division: null,
        T_Kind: null,
      };
      const objPost = {
        Category: "S",
        Identifier: this.item.id,
        Doc_No: this.serviceDocNum ? this.serviceDocNum : null,
        StartDate: this.serviceStartTime
          ? this.$usDate(this.serviceStartTime)
          : null,
        EndDate: this.serviceEndTime ? this.$usDate(this.serviceEndTime) : null,
        Comment: this.serviceComment ? this.serviceComment : null,
        T_Year: this.serviceYear ? this.serviceYear : null,
        T_Division: null,
        T_Kind: null,
      };

      const putUrl = `StudentTraining/Put`;
      const postUrl = `StudentTraining/Post`;

      try {
        // await window.axios.post(postUrl, objPost);
        if (this.isExistServiceData) {
          await window.axios.put(putUrl, objPut);
        } else {
          await window.axios.post(postUrl, objPost);
          this.getServiceData();
        }
        this.$bvToast.toast(`儲存成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        this.$bvToast.toast(`儲存失敗` + e, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    clearServiceData() {
      this.serviceDocNum = "";
      this.serviceYear = "";
      // this.serviceStartTime = null;
      // this.serviceEndTime = null;
      this.serviceComment = "";
    },
    clickTabFour() {
      this.tab = 4;
      // this.getServiceData();
      // this.getServiceDetailData();
    },
    changeServicePlace() {
      this.serviceItems = [
        {
          docNum: "",
          startDate: "",
          servicePlace: "",
        },
      ];
    },
    deleteServiceItem() {
      // console.log("need API");
    },
  },
  mounted() {},
  watch: {
    pgyStartTime(v) {
      const occurtimes = this.countOccurences(this.pgyStartTime, "-");

      if (v && occurtimes == 2 && this.isNewPGYEndTime) {
        const time = this.$usDate(this.pgyStartTime);
        const time2 = moment(time)
          .add(2, "years")
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        this.pgyEndTime = this.$twDate2(time2);
        this.compontKey += 1;
      }
    },
    trainingStartTime(v) {
      const occurtimes = this.countOccurences(this.trainingStartTime, "-");

      if (v && this.trainingYear && occurtimes == 2) {
        const time = this.$usDate(this.trainingStartTime);
        const time2 = moment(time)
          .add(+this.trainingYear, "years")
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        this.trainingEndTime = this.$twDate2(time2);
        this.compontKey += 1;
      }
    },
    trainingYear(v) {
      let value = `${v}`;
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (value.length > 1) {
        value = value.substring(1, 2);
      }
      const result = value;
      this.$nextTick(() => (this.trainingYear = result));
      const occurtimes = this.countOccurences(this.trainingStartTime, "-");

      if (v && this.trainingYear && occurtimes == 2) {
        const time = this.$usDate(this.trainingStartTime);
        const time2 = moment(time)
          .add(+this.trainingYear, "years")
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        this.trainingEndTime = this.$twDate2(time2);
        this.compontKey += 1;
      }
    },
    otherPracticeStartTime(v) {
      const occurtimes = this.countOccurences(this.otherPracticeStartTime, "-");
      if (
        v &&
        this.otherPracticeYear &&
        occurtimes == 2 &&
        this.isNewOtherPracticeEndTime
      ) {
        const time = this.$usDate(this.otherPracticeStartTime);
        const time2 = moment(time)
          .add(+this.otherPracticeYear, "years")
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        this.otherPracticeEndTime = this.$twDate2(time2);
        this.compontKey += 1;
      }
    },
    otherPracticeYear(v) {
      let value = `${v}`;
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (value.length > 1) {
        value = value.substring(1, 2);
      }
      const result = value;
      this.$nextTick(() => (this.otherPracticeYear = result));
      const occurtimes = this.countOccurences(this.otherPracticeStartTime, "-");
      if (
        v &&
        this.otherPracticeYear &&
        occurtimes == 2 &&
        this.isNewOtherPracticeEndTime
      ) {
        const time = this.$usDate(this.otherPracticeStartTime);
        const time2 = moment(time)
          .add(+this.otherPracticeYear, "years")
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        this.otherPracticeEndTime = this.$twDate2(time2);
        this.compontKey += 1;
      }
    },
    serviceStartTime(v) {
      const occurtimes = this.countOccurences(this.serviceStartTime, "-");
      if (
        v &&
        this.serviceYear &&
        occurtimes == 2 &&
        this.isNewServiceDataEndTime
      ) {
        const time = this.$usDate(this.serviceStartTime);
        const time2 = moment(time)
          .add(+this.serviceYear, "years")
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        this.serviceEndTime = this.$twDate2(time2);
        this.compontKey += 1;
      }
    },
    serviceYear(v) {
      let value = `${v}`;
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (value.length > 2) {
        value = value.substring(1, 3);
      }
      const result = value;
      this.$nextTick(() => (this.serviceYear = result));

      const occurtimes = this.countOccurences(this.serviceStartTime, "-");

      if (
        v &&
        this.serviceYear &&
        occurtimes == 2 &&
        this.isNewServiceDataEndTime
      ) {
        const time = this.$usDate(this.serviceStartTime);
        const time2 = moment(time)
          .add(+this.serviceYear, "years")
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        this.serviceEndTime = this.$twDate2(time2);
        this.compontKey += 1;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.grid-head {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 6px;
}
.grid-content {
  display: flex;
  // grid-template-columns: repeat(34, max-content);
  grid-column-gap: 40px;
  // padding: 0 100px;
  margin-top: 20px;
  height: 100px;
  justify-content: center;
  border: 1px dashed #1d6dc9;
  padding-top: 30px;
  background: #b0d0f3;
}
.main-conmtent {
  margin-top: 20px;
  h5 {
    font-weight: 600;
  }
}
.main-grid-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 6px;
}
.main-conmtent-three {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 6px;
}

p {
  font-size: 2em;
  text-align: center;
}
.input-group-text {
  width: 100px;
  background: #0379fd;
  color: #fff;
}
div.main-conmtent > div > div > div.input-group-text {
  background: #7e2ca3;
  color: #fff;
}

div.input-group.special-col > div.input-group-prepend > div.input-group-text {
  width: 130px;
}
div.input-group.dtc-bg > div.input-group-prepend > div.input-group-text {
  background: #646969;
  color: #ffffff;
}

.input-group {
  margin-bottom: 10px;
}

::v-deep #editProgressRecord > .modal-dialog > .modal-content > .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
::v-deep .modal-content {
  margin-left: -90px;
  width: 116%;
}

hr.new {
  border-top: 1px dashed #cacaca;
}
h4.title {
  font-size: 20px;
  font-weight: 700;
}
.service-place {
  display: grid;
  grid-template-columns: repeat(3, 1fr) max-content;
  grid-column-gap: 6px;
  .service-delete {
    height: 38px;
    font-size: 14px;
  }
}
.dtc-grid-header,
.dtc-grid-header2,
.dtc-grid-header3,
.dtc-grid-header4 {
  display: grid;
  grid-template-columns: 80px 80px 120px 160px 120px 160px 160px 1fr;
  // grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    // word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    word-break: keep-all;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-top: 1px solid #dee2e5;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
  > div:last-child {
    // border-right: none;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 0;
    line-height: 46px;
    height: 46px;
  }
  .fee {
    text-align: right;
    padding-right: 10px;
  }
}
.dtc-grid-header3,
.dtc-grid-header4 {
  grid-template-columns: 80px 200px 320px 160px 160px 1fr;
}
::v-deep .modal-footer {
  display: none;
}
</style>
