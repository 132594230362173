<template>
  <b-modal
    @shown="updateItem"
    :title="`${ kindOfType.type[item.type] }公費生歷程備註總覽`"
    id="allProgressRemark"
    size="xl"
    @hide="doSometing"
  >
    <b-container fluid>
      <header class="dtc-grid-header my-dark">
        <div
          v-for="(item, i) in headers"
          :key="`headers${i}`"
          :title="item.name || item"
          @click="sort(item)"
        >
          {{ item.name || item }}
          <span v-show="item.sortDesc === null">
            <i class="fas fa-sort"></i>
          </span>
          <span v-show="item.sortDesc === false">
            <i class="fas fa-sort-up"></i>
          </span>
          <span v-show="item.sortDesc">
            <i class="fas fa-sort-down"></i>
          </span>
        </div>
      </header>
      <main
        v-if="!items.length"
        class="mt-2"
        style="grid-column: 1/-1;font-size:26px;text-align:center;font-weight:600"
      >
        暫無資料
      </main>
      <main
        class="dtc-grid-header2"
        v-for="(item, i) in items"
        :key="i"
        style="color:#39312E;"
        :style="
          i % 2 == 0
            ? 'background-color:#EDEDED ;'
            : 'background-color: #DDDDDD;'
        "
      >
        <div>
          {{ i + 1 }}
        </div>
        <div :title="item.type">
          {{ item.type }}
        </div>
        <div :title="item.Doc_No">
          {{ item.Doc_No || "暫無資料" }}
        </div>
        <div :title="item.Comment">
          {{ item.Comment || "暫無資料" }}
        </div>
      </main>
    </b-container>

    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right ml-3"
          @click="$bvModal.hide('allProgressRemark')"
          style="margin-right:19px"
          >關閉</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { store } from "@/store/global.js";
import { kindOfType } from "@/constant.js";

import moment from "moment";
const headers = ["序號", "類別", "公文號", "備註"];
export default {
  name: "allProgressRemark",
  props: ["divisionOptions"],
  data() {
    return {
      item: {},
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      items: [],
      headers,
      kindOfType,
    };
  },
  computed: {},
  methods: {
    doSometing() {
      this.$bvModal.hide("allProgressRemark");
    },
    updateItem() {
      this.item = { ...store.editItem };
      this.getData();
    },
    async getData() {
      const isDoc =
        Boolean(this.item.cat == "醫師") || Boolean(this.item.cat == "牙醫")
          ? true
          : false;
      const isTypeTwo = this.item.type == 2 ? true : false;
      const id = this.item.id;
      const urlP = `StudentTraining/Get?identifier=${id}&category=P`;
      const urlE = `StudentTraining/Get?identifier=${id}&category=E`;
      const urlS = `StudentTraining/Get?identifier=${id}&category=S`;
      const urlT = `StudentTraining/Get?identifier=${id}&category=T`;
      let arrP = [];
      let arrE = [];
      let arrS = [];
      let arrT = [];
      try {
        if (isDoc) {
          arrP = await window.axios.get(urlP);
          arrE = await window.axios.get(urlE);
          arrS = await window.axios.get(urlS);
          arrT = await window.axios.get(urlT);
        } else if (isTypeTwo) {
          arrS = await window.axios.get(urlS);
          arrT = await window.axios.get(urlT);
        } else {
          arrE = await window.axios.get(urlE);
          arrS = await window.axios.get(urlS);
          arrT = await window.axios.get(urlT);
        }
        this.items = [...arrP, arrE, arrS, ...arrT].flat();
        this.items = this.items.map((s) => {
          s.type =
            s.Category == "P"
              ? "PGY培訓"
              : s.Category == "S"
              ? "服務期間"
              : s.Category == "T"
              ? "服務期間明細"
              : s.Category == "E" && isDoc
              ? "專科培訓"
              : "其他醫事訓練";
          return s;
        });
      } catch (error) {
        this.$bvToast.toast(error, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped lang="scss">
.grid-head {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 6px;
}
.grid-content {
  display: flex;
  // grid-template-columns: repeat(34, max-content);
  grid-column-gap: 40px;
  // padding: 0 100px;
  margin-top: 20px;
  height: 100px;
  justify-content: center;
  border: 1px dashed #1d6dc9;
  padding-top: 30px;
  background: #b0d0f3;
}
.main-conmtent {
  margin-top: 20px;
  h5 {
    font-weight: 600;
  }
}
.main-grid-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 6px;
}
.main-conmtent-three {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 6px;
}

p {
  font-size: 2em;
  text-align: center;
}
.input-group-text {
  width: 100px;
  background: #0379fd;
  color: #fff;
}
div.main-conmtent > div > div > div.input-group-text {
  background: #7e2ca3;
  color: #fff;
}

div.input-group.special-col > div.input-group-prepend > div.input-group-text {
  width: 130px;
}
div.input-group.dtc-bg > div.input-group-prepend > div.input-group-text {
  background: #646969;
  color: #ffffff;
}

.input-group {
  margin-bottom: 10px;
}

::v-deep #allProgressRemark > .modal-dialog > .modal-content > .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
::v-deep .modal-content {
  margin-left: -90px;
  width: 116%;
}

hr.new {
  border-top: 1px dashed #cacaca;
}
h4.title {
  font-size: 20px;
  font-weight: 700;
}
.service-place {
  display: grid;
  grid-template-columns: repeat(3, 1fr) max-content;
  grid-column-gap: 6px;
  .service-delete {
    height: 38px;
    font-size: 14px;
  }
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 80px 140px 120px 1fr;
  // grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    // word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    word-break: keep-all;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-top: 1px solid #dee2e5;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 0;
    line-height: 46px;
    height: 46px;
  }
  .fee {
    text-align: right;
    padding-right: 10px;
  }
}
.my-dark {
  > div {
    // background: var(--gray);
    background: #424242;
    color: white;
  }
}
</style>
